import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Email(props) {
  const [email, emailSetter] = useState(props.email || '');
  const [password, passwordSetter] = useState(props.password || '');
  const [enableEmail, enableEmailSetter] = useState(!!props.enabled);
  const [emailError, emailErrorSetter] = useState(null);
  const [passwordError, passwordErrorSetter] = useState(null);

  const validateInput = () => {
    const emailResult = !!email.trim(); // Check if email is not empty
    const passwordResult = !!password.trim(); // Check if password is not empty
    emailErrorSetter(!emailResult);
    passwordErrorSetter(!passwordResult);
    return emailResult && passwordResult;
  };

  const onBlur = (setter, field, event) => {
    setter(!event.target.value.trim()); // Check if the field is empty
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Email</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-email">
                    Email
                  </label>
                  <FormGroup
                    className={
                      emailError === null
                        ? ''
                        : emailError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="e.g something@email.com"
                      type="text"
                      defaultValue={email}
                      onChange={(event) => {
                        emailSetter(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(emailErrorSetter, 'email', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-password">
                    Password
                  </label>
                  <FormGroup
                    className={
                      passwordError === null
                        ? ''
                        : passwordError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-password"
                      placeholder="e.g FOOD-"
                      type="password"
                      defaultValue={password}
                      onChange={(event) => {
                        passwordSetter(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(passwordErrorSetter, 'password', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-enable">
                    Enable/Disable
                  </label>
                  <FormGroup>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={enableEmail}
                        type="checkbox"
                        onChange={(event) => {
                          enableEmailSetter(event.target.checked);
                        }}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    disabled
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Email;
