import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Stripe(props) {
  const [publishableKey, setPublishableKey] = useState(props.publishableKey || '');
  const [secretKey, setSecretKey] = useState(props.secretKey || '');
  const [publishableKeyError, setPublishableKeyError] = useState(null);
  const [secretKeyError, setSecretKeyError] = useState(null);

  const validateInput = () => {
    const publishableKeyResult = !!publishableKey.trim(); // Check if publishableKey is not empty
    const secretKeyResult = !!secretKey.trim(); // Check if secretKey is not empty
    setPublishableKeyError(!publishableKeyResult);
    setSecretKeyError(!secretKeyResult);
    return publishableKeyResult && secretKeyResult;
  };

  const onBlur = (setter, field, event) => {
    setter(!event.target.value.trim()); // Check if the field is empty
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Stripe</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-publishablekey">
                    Publishable Key
                  </label>
                  <FormGroup
                    className={
                      publishableKeyError === null
                        ? ''
                        : publishableKeyError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-publishablekey"
                      placeholder="e.g pk_test_lEaBbVGnTkzja2FyFiNlbqtw"
                      type="text"
                      defaultValue={publishableKey}
                      onChange={(event) => {
                        setPublishableKey(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(setPublishableKeyError, 'publishableKey', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-secretkey">
                    Secret Key
                  </label>
                  <FormGroup
                    className={
                      secretKeyError === null
                        ? ''
                        : secretKeyError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-secretkey"
                      placeholder="e.g sk_test_rKNqVc2tSkdgZHNO3XnPCLn4"
                      type="text"
                      defaultValue={secretKey}
                      onChange={(event) => {
                        setSecretKey(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(setSecretKeyError, 'secretKey', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    disabled
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Stripe;
