
// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// import "assets/plugins/nucleo/css/nucleo.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "assets/scss/argon-dashboard-react.scss";

// import AdminLayout from "layouts/Admin.js";
// import AuthLayout from "layouts/Auth.js";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// const baseUrl = 'https://https://api.aristonvillagepizza.uk';

// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/admin/*" element={<AdminLayout />} baseUrl={baseUrl}/>
//       <Route path="/auth/*" element={<AuthLayout />} />
//       {/* <Route path="*" element={<Navigate to="/admin/index" replace />} /> */}
//     </Routes>
//   </BrowserRouter>
// );


import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import React from 'react';
import App from './App';

import { createRoot } from 'react-dom/client';

const root = document.getElementById('root');
const reactRoot = createRoot(root);
reactRoot.render(<App />);
