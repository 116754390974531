import React, { useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
} from "reactstrap";

const users = [
  {
    id: 1,
    name: "John Doe",
    email: "john@example.com",
    role: "Admin",
    status: "Active",
  },
  {
    id: 2,
    name: "Alice Smith",
    email: "alice@example.com",
    role: "User",
    status: "Active",
  },
  {
    id: 3,
    name: "Bob Johnson",
    email: "bob@example.com",
    role: "User",
    status: "Inactive",
  },
  {
    id: 4,
    name: "Eve Adams",
    email: "eve@example.com",
    role: "Admin",
    status: "Active",
  },
  // Add more users here
];

const User = () => {
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const toggleSort = (key) => {
    if (sortKey === key) {
      // If already sorted by this key, toggle the order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If sorting by a different key, set it to ascending order
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const sortedUsers = users.sort((a, b) => {
    const keyA = sortKey ? a[sortKey] : null;
    const keyB = sortKey ? b[sortKey] : null;

    if (sortOrder === "asc") {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    } else {
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    }
  });

  return (
    <div>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">User List</h3>
              </CardHeader>

              <Table>
                <thead className="thead-light">
                  <tr>
                    <th
                      scope="col"
                      onClick={() => toggleSort("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Name{" "}
                      {sortKey === "name" && (
                        <span>
                          {sortOrder === "asc" ? (
                            <i className="fas fa-arrow-up"></i>
                          ) : (
                            <i className="fas fa-arrow-down"></i>
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => toggleSort("email")}
                      style={{ cursor: "pointer" }}
                    >
                      Email{" "}
                      {sortKey === "email" && (
                        <span>
                          {sortOrder === "asc" ? (
                            <i className="fas fa-arrow-up"></i>
                          ) : (
                            <i className="fas fa-arrow-down"></i>
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => toggleSort("role")}
                      style={{ cursor: "pointer" }}
                    >
                      Role{" "}
                      {sortKey === "role" && (
                        <span>
                          {sortOrder === "asc" ? (
                            <i className="fas fa-arrow-up"></i>
                          ) : (
                            <i className="fas fa-arrow-down"></i>
                          )}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => toggleSort("status")}
                      style={{ cursor: "pointer" }}
                    >
                      Status{" "}
                      {sortKey === "status" && (
                        <span>
                          {sortOrder === "asc" ? (
                            <i className="fas fa-arrow-up"></i>
                          ) : (
                            <i className="fas fa-arrow-down"></i>
                          )}
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>{user.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default User;
