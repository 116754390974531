import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import NavBar from '../components/Navbars/AdminNavbar';

const MainLayout = ({ children }) => (
  <div>
    <Sidebar />
    <div className="main-content">
      <NavBar />
      {children}
    </div>
  </div>
);

export default MainLayout;
