import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import Index from 'views/Index';
import Category from 'views/Category';
import Menu from 'views/Food';
import Option from 'views/OptionGroup';
import Order from 'views/Order';
import Rating from 'views/Rating';
import Rider from 'views/Rider';
import Configuration from 'views/Configuration';
import User from 'views/User';
import Login from 'views/examples/Login';
import Register from 'views/examples/Register';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout><Index /></MainLayout>} />
        <Route path="/menu" element={<MainLayout><Menu /></MainLayout>} />
        <Route path="/category" element={<MainLayout><Category /></MainLayout>} />
        <Route path="/options" element={<MainLayout><Option /></MainLayout>} />
        <Route path="/order" element={<MainLayout><Order /></MainLayout>} />
        <Route path="/ratings" element={<MainLayout><Rating /></MainLayout>} />
        <Route path="/riders" element={<MainLayout><Rider /></MainLayout>} />
        <Route path="/config" element={<MainLayout><Configuration /></MainLayout>} />
        <Route path="/users" element={<MainLayout><User /></MainLayout>} />
        <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
        <Route path="/register" element={<AuthLayout><Register /></AuthLayout>} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
