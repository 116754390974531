import React, { useEffect, useState } from "react";
import config from "config";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Form,
  FormGroup,
  Label,
  Input,
  Badge
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { FaEdit, FaTrash } from "react-icons/fa";

function Option() {
  const [options, setOptions] = useState([]);
  const [newOptionslug, setNewOptionslug] = useState("");
  const [newOption, setNewOption] = useState("");
  const [newOptionDesc, setNewOptionDesc] = useState("");
  const [editingOption, setEditingOption] = useState(null);
  const [nameError, setNameError] = useState("");
  const [slugError, setSlugError] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);


  useEffect(() => {
    fetchOptions();
  }, []);

  const openDeleteConfirmation = (index) => {
  setOptionToDelete(options[index]);
  setDeleteConfirmation(true);
  };

  const fetchOptions = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/extras/`);
      if (response.ok) {
        const data = await response.json();
        setOptions(data);
      } else {
        console.error("Error fetching options");
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleEditOption = (index) => {
    setEditingOption(options[index]);
    setNewOption(options[index].name);
    setNewOptionslug(options[index].slug);
    setNewOptionDesc(options[index].description);
  };

  const handleAddOption = async () => {

    if (newOption.trim() === "") {
      setNameError("Please enter a name*");
      return; // Don't proceed if there's an error
    }
      if (newOptionslug.trim() === "") {
      setSlugError("Please enter a slug*");
      return; // Don't proceed if there's an error
    }
    try {
      if (editingOption) {
        const response = await fetch(
          `${config.baseUrl}/extras/${editingOption.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: newOption, slug: newOptionslug, description: newOptionDesc }),
          }
        );
  
        if (response.ok) {
          const updatedOptions = options.map((option, index) =>
            index === options.indexOf(editingOption)
              ? { ...option, name: newOption, slug: newOptionslug ,description: newOptionDesc }
              : option
          );
          setEditingOption(null);
          setNewOption("");
          setNewOptionDesc("");
          setOptions(updatedOptions);
        } else {
          console.error("Error updating option");
        }
      } else {
        const response = await fetch(`${config.baseUrl}/extras/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newOption, slug: newOptionslug, description: newOptionDesc }),
        });
  
        if (response.ok) {
          const newOptionData = await response.json();
          setOptions([...options, newOptionData]); 
          setNewOption("");
          setNewOptionDesc("");
        } else {
          console.error("Error adding option");
        }
      }
    } catch (error) {
      console.error("Error adding/updating option:", error);
    }
  };
  const clearNameError = () => {
    setNameError("");
  };
  const clearSlugError = () => {
    setSlugError("");
  };
  const handleDeleteOption = async (index) => {
    const deletedOption = options[index];
    if (deletedOption) {
      try {
        const response = await fetch(
          `${config.baseUrl}/extras/${deletedOption.id}/`,
          {
            method: "DELETE",
          }
        );
  
        if (response.ok) {
          const updatedOptions = options.filter((option, i) => i !== index);
          setOptions(updatedOptions);
        } else {
          console.error("Error deleting option. Response status:", response.status);
        }
      } catch (error) {
        console.error("Error deleting option:", error);
      }
    } else {
      console.error("Deleted option is undefined");
    }
  };
   const confirmDeleteOption = async () => {
  if (optionToDelete) {
    await handleDeleteOption(options.indexOf(optionToDelete));
    setOptionToDelete(null);
    setDeleteConfirmation(false);
  }
};
const cancelDelete = () => {
  setOptionToDelete(null);
  setDeleteConfirmation(false);
};

  
  return (
    <>
      <Row className="mb-5">
        <Col md="6">
          <Card className="shadow">
            <CardHeader>
              <h2>Option Groups</h2>
            </CardHeader>
            <div>
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Slug</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {options.map((option, index) => (
                    <tr key={index}>
                      <td>{option.name}</td>
                      <td>{option.slug}</td>
                      <td>{option.description}</td>
                      <td>
                        <FontAwesomeIcon
                        icon={faEdit}
                        href='#edit'
                          style={{ color: "#2d74d6" , cursor: "pointer" }}
                          onClick={() => handleEditOption(index)}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon
                          icon={faTrash}
                          href="#delete"
                          style={{ color: "red" , cursor: "pointer" }}
                          onClick={() => openDeleteConfirmation(index)}
                        />

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col md="6">
          <Card className="shadow">
            <CardHeader>
              <h2>{editingOption ? "Edit option Group" : "Add Option Group"}</h2>
            </CardHeader>
            <div className="p-4">
              <Form>
                <FormGroup>
                  <Label for="newOption">Name <span style={{ color: "red" }}>*</span></Label>
                  <Input
                    type="text"
                    id="newOption"
                    placeholder="Enter Option Name"
                    value={newOption}
                    onChange={(e) => {
                    setNewOption(e.target.value);
                    clearNameError();
                    }}
                  />
                  {nameError && <div className="text-danger">{nameError}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="newOption">Slug <span style={{ color: "red" }}>*</span></Label>
                  <Input
                    type="text"
                    id="newOptionslug"
                    placeholder="Enter Option Slug"
                    value={newOptionslug}
                    onChange={(e) => {
                    setNewOptionslug(e.target.value);
                    clearSlugError();
                    }}
                  />
                  {slugError && <div className="text-danger">{slugError}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="newOptionDec">Description</Label>
                  <Input
                    type="text"
                    id="newOptionDec"
                    placeholder="Enter Option Description"
                    value={newOptionDesc}
                    onChange={(e) => setNewOptionDesc(e.target.value)}
                  />
                </FormGroup>
                <Button color="primary" onClick={handleAddOption}>
                  {editingOption ? "Save Edit" : "Add Option"}
                </Button>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
      {deleteConfirmation && (
  <Modal isOpen={deleteConfirmation} toggle={cancelDelete} centered>
    <ModalHeader toggle={cancelDelete}>Confirm Deletion</ModalHeader>
    <ModalBody>
      Are you sure you want to delete this option group?
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={cancelDelete}>
        Cancel
      </Button>
      <Button color="danger" onClick={confirmDeleteOption}>
        Delete
      </Button>
    </ModalFooter>
  </Modal>
)}

    </>
  );
}

export default Option;
