import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Paypal(props) {
  const [clientId, setClientId] = useState(props.clientId || '');
  const [clientSecret, setClientSecret] = useState(props.clientSecret || '');
  const [sandbox, setSandbox] = useState(!!props.sandbox);
  const [clientIdError, setClientIdError] = useState(null);
  const [clientSecretError, setClientSecretError] = useState(null);

  const validateInput = () => {
    const clientIdResult = !!clientId.trim(); // Check if clientId is not empty
    const clientSecretResult = !!clientSecret.trim(); // Check if clientSecret is not empty
    setClientIdError(!clientIdResult);
    setClientSecretError(!clientSecretResult);
    return clientIdResult && clientSecretResult;
  };

  const onBlur = (setter, field, event) => {
    setter(!event.target.value.trim()); // Check if the field is empty
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Paypal</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-clientid">
                    Client ID
                  </label>
                  <FormGroup
                    className={
                      clientIdError === null
                        ? ''
                        : clientIdError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-clientid"
                      placeholder="e.g AeGIgSX--JEVwoQgLjGOb8gh1DUJG0MFVgLc2mBIe6_V5NefV0LM3L78m01fLLI6U2FFB-qJr4ErrtL1"
                      type="text"
                      defaultValue={clientId}
                      onChange={(event) => {
                        setClientId(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(setClientIdError, 'clientId', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-clientsecret">
                    Client Secret
                  </label>
                  <FormGroup
                    className={
                      clientSecretError === null
                        ? ''
                        : clientSecretError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-clientsecret"
                      placeholder="e.g EHAP6CSZt3kwzcpdxrpw16PqHEspw5wtJCVVux_95e2Qcwbeh6mQp9GncEbxnVFkEbJu4z1i-GuDDthf"
                      type="text"
                      defaultValue={clientSecret}
                      onChange={(event) => {
                        setClientSecret(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(setClientSecretError, 'clientSecret', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-sandbox">
                    Sandbox
                  </label>
                  <FormGroup>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={sandbox}
                        type="checkbox"
                        onChange={(event) => {
                          setSandbox(event.target.checked);
                        }}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    disabled
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Paypal;
