import React from 'react';
import { Container } from 'reactstrap';
import OrderConfiguration from '../components/Configuration/Order/Order.jsx';
import EmailConfiguration from '../components/Configuration/Email/Email.jsx';
import PaypalConfiguration from '../components/Configuration/Paypal/Paypal.jsx';
import StripeConfiguration from '../components/Configuration/Stripe/Stripe.jsx';
import DeliveryConfiguration from '../components/Configuration/Delivery/Delivery.jsx';
import CurrencyConfiguration from '../components/Configuration/Currency/Currency.jsx';

const dummyData = {
  order_id_prefix: 'FOOD-',
  email: 'example@example.com',
  password: 'FOOD-123',
  enable_email: true,
  client_id: 'pk_test_lEaBbVGnTkzja2FyFiNlbqtw',
  client_secret: 'sk_test_rKNqVc2tSkdgZHNO3XnPCLn4',
  sandbox: true,
  publishable_key: 'pk_test_lEaBbVGnTkzja2FyFiNlbqtw',
  secret_key: 'sk_test_rKNqVc2tSkdgZHNO3XnPCLn4',
  delivery_charges: 5.99,
  currency: 'USD',
  currency_symbol: '$',
};

const Configuration = () => {
  return (
    <>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <OrderConfiguration prefix={dummyData.order_id_prefix} />
        <EmailConfiguration
          email={dummyData.email}
          password={dummyData.password}
          enabled={dummyData.enable_email}
        />
        <PaypalConfiguration
          clientId={dummyData.client_id}
          clientSecret={dummyData.client_secret}
          sandbox={dummyData.sandbox}
        />
        <StripeConfiguration
          publishableKey={dummyData.publishable_key}
          secretKey={dummyData.secret_key}
        />
        <DeliveryConfiguration deliveryCharges={dummyData.delivery_charges} />
        <CurrencyConfiguration
          currencyCode={dummyData.currency}
          currencySymbol={dummyData.currency_symbol}
        />
      </Container>
    </>
  );
};

export default Configuration;
