import Option from '../components/Option/Option';
import OptionItem from '../components/Option/OptionItem';
import {
  Container,

} from 'reactstrap';

function OptionGroup() {
 

  return (
    <>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
       <Option/>
       <OptionItem />
      </Container>
    </>
  );
}

export default OptionGroup;
