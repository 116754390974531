import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  Badge,
  Modal, ModalHeader, ModalBody, ModalFooter,
  CardHeader,
  Table,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  CardBody,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';


import Food from "components/Food/Food";
// import { FaTimes } from "react-icons/fa"; 
import config from "config";
function Foods() {
  const [editModal, setEditModal] = useState(false);
  const [foods, setFoods] = useState([]);
  const [file, setFile] = useState(null);
  const [variationModalOpen, setVariationModalOpen] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [newVariationName, setNewVariationName] = useState("");
  const [newVariationDesc, setNewVariationDesc] = useState("");
  const [newVariationPrice, setNewVariationPrice] = useState("");
  const [editVariation, setEditVariation] = useState(null);
  const [selectedFoodForVariation, setSelectedFoodForVariation] = useState(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [foodToDelete, setFoodToDelete] = useState(null);

  const [deleteVariationConfirmation, setDeleteVariationConfirmation] = useState(false);
  const [variationToDelete, setVariationToDelete] = useState(null);

  const [variationNameError, setVariationNameError] = useState("");
  


  const toggleModal = (foods) => {
    setEditModal(!editModal);
    setFoods(foods);
  };

  const fetchFoods = () => {
    fetch(`${config.baseUrl}/products/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setFoods(data);
        } else {
          setFoods([]);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchFoods();
  }, []);

  const addFood = async (newFood) => {
    try {
      const formData = new FormData();
      formData.append("name", newFood.name);
      formData.append("slug", newFood.slug);
      formData.append("itemno", newFood.itemno);
      formData.append("category", newFood.category);
      formData.append("feature", newFood.feature);
      formData.append("available", newFood.available);

      if (newFood.description) {
        formData.append("description", newFood.description);
      }

      if (newFood.price) {
        formData.append("price", newFood.price);
      }

      if (newFood.image) {
        formData.append("image", newFood.image);
      }

      if (newFood.ptype) {
        formData.append("ptype", newFood.ptype);
      }

      const response = await fetch(`${config.baseUrl}/products/`, {
        method: "POST",
        body: formData,
      });

      console.log(formData, newFood);

      if (!response.ok) {
        console.error("Error Response:", response.status, response.statusText);
        const errorData = await response.text();
        console.error("Error Data:", errorData);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setFoods((prevFoods) => [...prevFoods, data]);
      setEditModal(false);
    } catch (error) {
      console.error("Error adding food:", error);
    }
  };

  const deleteFood = async (foodId) => {
    try {
      await fetch(`${config.baseUrl}/products/${foodId}/`, {
        method: "DELETE",
      });
      setFoods((prevFoods) => prevFoods.filter((food) => food.id !== foodId));
    } catch (error) {
      console.error("Error deleting food:", error);
    }
  };

  const editFood = (editedFood) => {
    const formData = new FormData();
    formData.append('name', editedFood.name);

    formData.append('slug', editedFood.slug);
    formData.append('itemno', editedFood.itemno);
    formData.append('category', editedFood.category);
    formData.append('feature', editedFood.feature);
    formData.append('available', editedFood.available);

    if (editedFood.price) {
      formData.append('price', editedFood.price);
    }
    if (editedFood.description) {
      formData.append('description', editedFood.description);
    }
    if (editedFood.image) {
      formData.append('image', editedFood.image);
    }

    if (editedFood.ptype) {
      formData.append('ptype', editedFood.ptype);
    }

    fetch(`${config.baseUrl}/products/${editedFood.id}/`, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setFoods((prevFoods) => {
          if (Array.isArray(prevFoods)) {
            return prevFoods.map((food) =>
              food.id === data.id ? data : food
            );
          }
          return prevFoods;
        });
        setEditModal(false);
        fetchFoods();
      })
      .catch((error) => {
        console.error('Error editing food:', error);
      });
  };

  const actionButtons = (food) => {
    return (
      <>
        
        <FontAwesomeIcon
          style={{ color: "#2d74d6", cursor: "pointer" }}
          href="#pablo"
          icon={faEdit}
          onClick={(e) => {
            e.preventDefault();
            toggleModal(food);
          }}
          color="success"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FontAwesomeIcon
          href="#pablo"
          style={{ color: "red", cursor: "pointer" }}
          icon={faTrash}
          onClick={(e) => {
            e.preventDefault();
            openDeleteConfirmation(food);
          }}
          color="danger"
        />
      </>
    );
  };


  const openDeleteConfirmation = (food) => {
    setFoodToDelete(food);
    setDeleteConfirmation(true);
  };
  const confirmDeleteFood = async () => {
    if (foodToDelete) {
      await deleteFood(foodToDelete.id);
      setDeleteConfirmation(false);
    }
  };
  const cancelDelete = () => {
    setFoodToDelete(null);
    setDeleteConfirmation(false);
  };


  const setSelectedFood = (selectedFood) => {
    setFoods(selectedFood);
  };

  const loadVariations = async (product) => {
    try {
      const response = await fetch(
        `${config.baseUrl}/variations/?product=${product}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const variationsForProduct = data.filter(
        (variation) => variation.product === product
      );
      setSelectedVariation(variationsForProduct);
      setVariationModalOpen(true);
    } catch (error) {
      console.error("Error fetching variations:", error);
    }
  };

  const handleDeleteVariation = async (variationId) => {
    try {
      const response = await fetch(
        `${config.baseUrl}/variations/${variationId}/`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        console.error(
          "Error deleting variation:",
          response.status,
          response.statusText
        );
        const errorData = await response.text();
        console.error("Error Data:", errorData);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setSelectedVariation((prevVariations) =>
        prevVariations.filter((variation) => variation.id !== variationId)
      );
      // setVariationModalOpen(false);
    } catch (error) {
      console.error("Error deleting variation:", error);
    }
  };


  const handleAddNewVariation = async () => {

    if (!newVariationName || newVariationName.length < 2) {
      setVariationNameError("Enter a valid name for the variation * ");
      return;
    }
    setVariationNameError("");

    try {
      if (!selectedFoodForVariation) {
        console.error("Food ID is not available. Cannot add a new variation.");
        return;
      }

      const response = await fetch(`${config.baseUrl}/variations/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: newVariationName,
          description: newVariationDesc,
          price: newVariationPrice,
          product: selectedFoodForVariation.id,
        }),
      });

      if (!response.ok) {
        console.error(
          "Error adding new variation:",
          response.status,
          response.statusText
        );
        const errorData = await response.text();
        console.error("Error Data:", errorData);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      loadVariations(selectedFoodForVariation.id);

    } catch (error) {
      console.error("Error adding new variation:", error);
    }
  };


  const handleEditVariation = (variation) => {
    setEditVariation(variation);
    setNewVariationName(variation.name);
    setNewVariationPrice(variation.price);
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (editVariation) {
      if (!newVariationName || newVariationName.length < 2) {
        setVariationNameError("Enter a valid name for the variation * ");
        return;
      }

      setVariationNameError("");
      try {
        const response = await fetch(
          `${config.baseUrl}/variations/${editVariation.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: newVariationName,
              description: newVariationDesc,
              price: newVariationPrice,
              product: editVariation.product,
            }),
          }
        );

        if (!response.ok) {
          console.error(
            "Error editing variation:",
            response.status,
            response.statusText
          );
          const errorData = await response.text();
          console.error("Error Data:", errorData);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        loadVariations(editVariation.product);
        setEditVariation(null);
        setNewVariationName("");
        setNewVariationDesc("");
        setNewVariationPrice("");
      } catch (error) {
        console.error("Error editing variation:", error);
      }
    }
  }


  const handleCloseVariationPopup = () => {
    setVariationModalOpen(false);

  };
  const openDeleteVariationConfirmation = (variation) => {
    setVariationToDelete(variation);
    setDeleteVariationConfirmation(true);
  };
  const confirmDeleteVariation = async () => {
    if (variationToDelete) {
      await handleDeleteVariation(variationToDelete.id);
      setDeleteVariationConfirmation(false);
    }
  };
  const cancelDeleteVariation = () => {
    setVariationToDelete(null);
    setDeleteVariationConfirmation(false);
  };


  return (
    <>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Food
          addFood={addFood}
          fetchFoods={fetchFoods}
          isEdit={false}
          file={file}
          setFile={setFile}
        />
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Menu List</h3>
              </CardHeader>
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Available</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>Popular</th>
                    <th>Variations</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {foods && foods.length > 0 ? (
                    foods.map((food) => (
                      <tr key={food.id}>
                        <td>{food.itemno}</td>
                        <td style={{ textAlign: "center" }} >
                          {food.available ? (
                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                          )}
                        </td>
                        <td>
                          {food.image && (
                            <img
                              src={config.baseUrl + food.image}
                              alt={food.name}
                              style={{ maxWidth: "50px", maxHeight: "50px" }}
                            />
                          )}
                        </td>
                        <td>{food.name}</td>
                        <td>{food.category_name}</td>
                        <td>{food.price}</td>
                        
                        <td style={{ textAlign: "center" }} >
                          {food.feature ? (
                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                          )}
                        </td>

                        <td>
                          <Badge
                            href="#pablo"
                            onClick={(e) => {

                              setSelectedFoodForVariation(food);
                              loadVariations(food.id);
                            }}
                            color="success"
                          >
                            Add Variations
                          </Badge>
                            {food.variations.map((variation, index) => (
                              <li key={index}>
                                {variation.name},{" "}
                                {variation.price},{" "}
                                
                              </li>
                            ))}
                          
                        </td>
                        <td>{actionButtons(food, setSelectedFood)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {variationModalOpen && (
          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={variationModalOpen}
            toggle={() => setVariationModalOpen(false)}
          >
            <Card>
              <CardHeader className="variation__header">
                <h3>Variations</h3>
                <a href="#close"
                  className="close-button"
                  onClick={handleCloseVariationPopup}
                >
                  <i className="fa fa-times" />
                </a>

              </CardHeader>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedVariation && selectedVariation.length > 0 ? (
                    selectedVariation.map((variation) => (
                      <tr key={variation.id}>
                        <td>{variation.name}</td>
                        <td>{variation.description}</td>
                        <td>{variation.price}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ color: "#2d74d6", cursor: "pointer" }}
                            onClick={() => handleEditVariation(variation)}
                            color="primary"
                          />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => openDeleteVariationConfirmation(variation)}
                            color="danger"
                          />

                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No variation is present</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Card>
                <CardBody>
                  <h3>
                    {editVariation ? "Edit Variation" : "Add New Variation"}
                  </h3>
                  <Form
                    className="p-lg-4 pt-0"
                    onSubmit={(e) => {
                      e.preventDefault(); // Add preventDefault
                      return editVariation ? handleEditSubmit(e) : handleAddNewVariation(foods.id);
                    }}
                  >
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Label for="newVariationName">Name <span style={{ color: "red" }}>*</span></Label>
                          <Input
                            type="text"
                            name="name"
                            id="newVariationName"
                            value={newVariationName}
                            onChange={(e) =>{
                              setNewVariationName(e.target.value)
                              setVariationNameError("");}
                            }
                            
                          />
                          {variationNameError && <div className="text-danger">{variationNameError}</div>}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Label for="newVariationDesc">Description</Label>
                          <Input
                            type="text"
                            name="desc"
                            id="newVariationDesc"
                            value={newVariationDesc}
                            onChange={(e) =>
                              setNewVariationDesc(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Label for="newVariationPrice">Price </Label>
                          <Input
                            min={0}
                            max={100} 
                            type="integer"
                            name="price"
                            id="newVariationPrice"
                            value={newVariationPrice}
                            onChange={(e) =>
                              setNewVariationPrice(e.target.value)
                               
                            }
                             
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="success" type="submit">
                      {editVariation ? "Edit Variation" : "Add New Variation"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Card>
          </Modal>
        )}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal();
          }}
        >
          <Food
            food={foods}
            editFood={editFood}
            isEdit={true}
            file={file}
            setFile={setFile}
          />
        </Modal>


        {deleteConfirmation && (
          <Modal isOpen={deleteConfirmation} toggle={cancelDelete} centered>
            <ModalHeader toggle={cancelDelete}>Confirm Deletion</ModalHeader>
            <ModalBody>
              Are you sure you want to delete this food?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button color="danger" onClick={confirmDeleteFood}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}


        {deleteVariationConfirmation && (
          <Modal isOpen={deleteVariationConfirmation} toggle={cancelDeleteVariation} centered>
            <ModalHeader toggle={cancelDeleteVariation}>Confirm Deletion</ModalHeader>
            <ModalBody>
              Are you sure you want to delete this variation?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={cancelDeleteVariation}>
                Cancel
              </Button>
              <Button color="danger" onClick={confirmDeleteVariation}>
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}


      </Container>
    </>
  );
}

export default Foods;
