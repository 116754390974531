import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Currency(props) {
  const [currencyCode, setCurrencyCode] = useState(props.currencyCode || '');
  const [currencySymbol, setCurrencySymbol] = useState(props.currencySymbol || '');
  const [currencyCodeError, setCurrencyCodeError] = useState(null);
  const [currencySymbolError, setCurrencySymbolError] = useState(null);

  const onBlur = (setter, value) => {
    setter(!value.trim()); // Check if the field is empty
  };

  const validateInput = () => {
    const currencyCodeResult = !!currencyCode;
    const currencySymbolResult = !!currencySymbol;
    setCurrencyCodeError(!currencyCodeResult);
    setCurrencySymbolError(!currencySymbolResult);
    return currencyCodeResult && currencySymbolResult;
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Currency</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="6">
                  <label className="form-control-label" htmlFor="input-currencycode">
                    Currency Code
                  </label>
                  <FormGroup
                    className={
                      currencyCodeError === null
                        ? ''
                        : currencyCodeError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-currencycode"
                      placeholder="Currency Code"
                      type="text"
                      defaultValue={currencyCode}
                      onChange={(event) => {
                        setCurrencyCode(event.target.value);
                      }}
                      onBlur={() => {
                        onBlur(setCurrencyCodeError, currencyCode);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <label className="form-control-label" htmlFor="input-currencysymbol">
                    Currency Symbol
                  </label>
                  <FormGroup
                    className={
                      currencySymbolError === null
                        ? ''
                        : currencySymbolError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-currencysymbol"
                      placeholder="Currency Symbol"
                      type="text"
                      defaultValue={currencySymbol}
                      onChange={(event) => {
                        setCurrencySymbol(event.target.value);
                      }}
                      onBlur={() => {
                        onBlur(setCurrencySymbolError, currencySymbol);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    disabled
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Currency;
