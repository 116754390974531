import React, { useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
} from "reactstrap";



const reviews = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      items: [
        { name: "Product 1", rating: 4, review: "Good product" },
        { name: "Product 2", rating: 5, review: "Excellent product" },
      ],
      rating: 4.5,
    },
    {
      id: 2,
      name: "Alice Smith",
      email: "alice@example.com",
      items: [
        { name: "Product 1", rating: 3, review: "Average product" },
        { name: "Product 3", rating: 2, review: "Not satisfied" },
      ],
      rating: 2.5,
    },
    {
      id: 3,
      name: "Bob Johnson",
      email: "bob@example.com",
      items: [
        { name: "Product 4", rating: 4, review: "Good quality" },
        { name: "Product 5", rating: 5, review: "Highly recommended" },
      ],
      rating: 4.5,
    },
    {
      id: 4,
      name: "Eve Adams",
      email: "eve@example.com",
      items: [
        { name: "Product 2", rating: 4, review: "Very nice" },
        { name: "Product 4", rating: 3, review: "Decent" },
      ],
      rating: 3.5,
    },
    // Add more reviews here
  ];
  
const Review = () => {
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
  
    const toggleSort = (key) => {
      if (sortKey === key) {
        // If already sorted by this key, toggle the order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If sorting by a different key, set it to ascending order
        setSortKey(key);
        setSortOrder("asc");
      }
    };
  
    const sortedReviews = reviews.sort((a, b) => {
      const keyA = sortKey ? a[sortKey] : null;
      const keyB = sortKey ? b[sortKey] : null;
  
      if (sortOrder === "asc") {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      } else {
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      }
    });
  
    return (
      <div>
        <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <h3 className="mb-0">Reviews</h3>
                </CardHeader>
  
                <Table>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        onClick={() => toggleSort("name")}
                        style={{ cursor: "pointer" }}
                      >
                        Name{" "}
                        {sortKey === "name" && (
                          <span>
                            {sortOrder === "asc" ? (
                              <i className="fas fa-arrow-up"></i>
                            ) : (
                              <i className="fas fa-arrow-down"></i>
                            )}
                          </span>
                        )}
                      </th>
                      <th
                        scope="col"
                        onClick={() => toggleSort("email")}
                        style={{ cursor: "pointer" }}
                      >
                        Email{" "}
                        {sortKey === "email" && (
                          <span>
                            {sortOrder === "asc" ? (
                              <i className="fas fa-arrow-up"></i>
                            ) : (
                              <i className="fas fa-arrow-down"></i>
                            )}
                          </span>
                        )}
                      </th>
                      <th scope="col">Items</th>
                      <th scope="col">Review</th>
                      <th
                        scope="col"
                        onClick={() => toggleSort("rating")}
                        style={{ cursor: "pointer" }}
                      >
                        Rating{" "}
                        {sortKey === "rating" && (
                          <span>
                            {sortOrder === "asc" ? (
                              <i className="fas fa-arrow-up"></i>
                            ) : (
                              <i className="fas fa-arrow-down"></i>
                            )}
                          </span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedReviews.map((review) => (
                      <tr key={review.id}>
                        <td>{review.name}</td>
                        <td>{review.email}</td>
                        <td>
                          {review.items.map((item, index) => (
                            <li key={index}>
                              {item.name} - {item.rating} stars
                            </li>
                          ))}
                        </td>
                        <td>{review.review}</td>
                        <td>{review.rating} stars</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  
  export default Review;