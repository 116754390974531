import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/brand/logo.png";

// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavbarToggler,
  Collapse,
  NavLink,
} from "reactstrap";

const Sidebar = (props) => {
  const location = useLocation();

  const isLinkActive = (path) => {
    // Check if the current location matches the link's "to" prop
    return location.pathname === path;
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white text-primary"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <NavbarBrand tag={Link} to="/">
          <img src={logo} alt="Logo" className="custom__logo" />
        </NavbarBrand>
        <NavbarToggler />
        <Collapse navbar className="full-screen-nav">
          <Nav className="custom__nav" navbar>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/"
                className={isLinkActive("/") ? "active" : ""}
              >
                <i className="ni ni-tv-2"></i>
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/menu"
                className={isLinkActive("/menu") ? "active" : ""}
              >
                <i className="fa-solid fa-utensils "></i>
                Menu
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/category"
                className={isLinkActive("/category") ? "active" : ""}
              >
                <i className="ni ni-chart-pie-35"></i>
                Category
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/options"
                className={isLinkActive("/options") ? "active" : ""}
              >
                <i className="ni ni-single-copy-04"></i>
                Options
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/order"
                className={isLinkActive("/order") ? "active" : ""}
              >
                <i className="ni ni-delivery-fast "></i>
                Order
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/riders"
                className={isLinkActive("/riders") ? "active" : ""}
              >
                <i className="ni ni ni-bus-front-12"></i>
                Rider
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/ratings"
                className={isLinkActive("/ratings") ? "active" : ""}
              >
                <i className="fas fa-star"></i>
                Ratings
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/config"
                className={isLinkActive("/config") ? "active" : ""}
              >
                <i className="ni ni-settings"></i>
                Configuration
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/users"
                className={isLinkActive("/users") ? "active" : ""}
              >
                <i className="fas fa-users "></i>
                Users
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/login"
                className={isLinkActive("/login") ? "active" : ""}
              >
                <i className="ni ni-key-25 "></i>
                Login
              </NavLink>
            </NavItem>
            <NavItem className="sm-only">
              <NavLink
                tag={Link}
                to="/register"
                className={isLinkActive("/register") ? "active" : ""}
              >
                <i className="ni ni-circle-08 "></i>
                Register
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
