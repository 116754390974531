import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import config from "config";
function OptionItemTable(props) {
  const { isEdit } = props;
  const [extra, seteExtra] = useState("");
  const [extras, seteExtras] = useState([]);
  const [name, setName] = useState(isEdit && props.option ? props.option.name : "");
  const [description, setDesc] = useState(isEdit && props.option ? props.option.description : "");
  const [price, setPrice] = useState(isEdit && props.option ? props.option.price : "");

  const [nameError, setNameError] = useState("");
  const [extraError, setExtraError] = useState("");


  useEffect(() => {
    if (props.option) {
      setName(props.option.name);
      setDesc(props.option.description);
      setPrice(props.option.price);
      seteExtra(props.option.extra);
    }
  }, [props.option]);

  // Fetch extras from the API
  useEffect(() => {
    fetch(`${config.baseUrl}/extras/`)
      .then((response) => response.json())
      .then((data) => {
        seteExtras(data);
      })
      .catch((error) => {
        console.error("Error fetching extras:", error);
      });
  }, []);

  const handleSave = () => {

    if (!name) {
      setNameError("Please enter name * ");
      return;
    }
    if (!extra) {
      setExtraError("Please select option group * ");
      return;
    }

    setNameError("");
    setExtraError("");

    const newOption = {
      name: name,
      description: description,
      price: price,
      extra: extra,
    };
    console.log(newOption);
    
    if (props.option) {
        newOption.id = props.option.id;
        props.editOptionItem(newOption);
      } else {
        props.addOptionItem(newOption);
      }
      
  
    // Reset form fields
    setName("");
    setDesc("");
    setPrice("");
    seteExtra("");
  };
  


  return (
    <>
      <Row>
        <Col className="order-xl-1">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {props.isEdit ? "Edit Option Item" : "Add Option Item"}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-title"
                        >
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          type="text"
                          value={name || ""}
                          onChange={(event) => {setName(event.target.value);
                          setNameError("");
                          }}
                        />
                        {nameError && (
                          <div className="text-danger">{nameError}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-Desc"
                        >
                          Description
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-Desc"
                          type="text"
                          value={description || ""}
                          onChange={(event) => setDesc(event.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-price"
                        >
                          Price 
                        </label>
                        <Input
                          min={0}
                          max={100} 
                          className="form-control-alternative"
                          id="input-price"
                          type="number"
                          value={price || ""}
                          onChange={(event) => setPrice(event.target.value)
                          }
                        />
                        
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-extra"
                        >
                          Option Group <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          name="select"
                          id="input-extra"
                          value={extra || ""}
                          onChange={(event) => {seteExtra(event.target.value);
                          setExtraError("");
                          }}
                        >
                        <option>Select</option>
                          {extras.map((e) => (
                            <option key={e.id} value={e.id || ''}>
                              {e.description}
                            </option>
                          ))}
                        </Input>
                        {extraError && (
                          <div className="text-danger">{extraError}</div>
                        )}
                      </FormGroup>
                    </Col>
                    
                    
                  </Row>

                </div>

                <Row className="mt-2 justify-content-center">
                  <Col>
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  );
}

export default OptionItemTable;