import React, { useState } from 'react';
import { Container, Row, Card, Badge, Modal, Table, CardHeader } from 'reactstrap';
import Rider from "components/Rider.js";

// Dummy data for riders (replace with your actual data)
const riders = [
  {
    id: 1,
    name: 'John Doe',
    username: 'johndoe007',
    password: '********',
    phone: '123-456-7890',
    available: true,
  },
  {
    id: 2,
    name: 'Alice Smith',
    username: 'alicesmith123',
    password: '********',
    phone: '987-654-3210',
    available: false,
  },
  // Add more rider objects as needed
];

function Riders(props) {
  const [editModal, setEditModal] = useState(false);
  const [rider, setRider] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = (rider) => {
    setEditModal(!editModal);
    setRider(rider);
  };

  const availableStatus = (row) => {
    return (
      <>
        {row.available}
        <label className="custom-toggle">
          {/* Toggle input */}
          <input
            defaultChecked={row.available}
            type="checkbox"
            onChange={(event) => {
              // Handle toggle logic here
            }}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </>
    );
  };

  const actionButtons = (row) => {
    return (
      <>
        <Badge
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            toggleModal(row);
          }}
          color="primary"
        >
          Edit
        </Badge>
        &nbsp;&nbsp;
        <Badge
          href="#pablo"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            // Handle delete logic here
            setIsOpen(true);
            setTimeout(() => {
              setIsOpen(false);
            }, 2000);
          }}
        >
          {'Delete'}
        </Badge>
      </>
    );
  };

  return (
    <>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Rider />
        {/* Table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <h3 className="mb-0">Riders</h3>
                </CardHeader>
  
                <Table responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Phone</th>
                      <th>Available</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Map through your rider data and render rows */}
                    {riders.map((rider) => (
                      <tr key={rider.id}>
                        <td>{rider.name}</td>
                        <td>{rider.username}</td>
                        <td>{rider.password}</td>
                        <td>{rider.phone}</td>
                        <td>{availableStatus(rider)}</td>
                        <td>{actionButtons(rider)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal();
          }}
        >
          <Rider rider={rider} />
        </Modal>
      </Container>
    </>
  );
}

export default Riders;
