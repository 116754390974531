import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormGroup,
  Input
} from "reactstrap";


const orders = [
  {
    id: 1,
    status: "Pending",
    user: {
      name: "John Doe",
      phone: "123-456-7890",
      email: "john@example.com",
    },
    items: [
      { name: "Item 1", quantity: 2, price: 10 },
      { name: "Item 2", quantity: 1, price: 15 },
    ],
    deliveryCharges: 5,
    total: 35,
    paymentMethod: "Credit Card",
    paidAmount: 35,
    datetime: "2023-10-10 14:30:00",
    address: "123 Main St, City",
  },
  // Add more orders here
];

const Order = () => {
  const [modal, setModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [customerCollapse, setCustomerCollapse] = useState(true); // Initially open
  const [orderCollapse, setOrderCollapse] = useState(true); // Initially open
  const [rejectReason, setRejectReason] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const toggleCustomerCollapse = () => {
    setCustomerCollapse(!customerCollapse);
  };

  const toggleOrderCollapse = () => {
    setOrderCollapse(!orderCollapse);
  };

  const viewOrderDetails = (order) => {
    setSelectedOrder(order);
    toggle();
  };

  const acceptOrder = () => {
    // Handle order acceptance logic here
    console.log(`Accepted order ${selectedOrder.id}`);
    toggle();
  };

  const rejectOrder = () => {
    // Handle order rejection logic here, including `rejectReason`
    console.log(
      `Rejected order ${selectedOrder.id} with reason: ${rejectReason}`
    );
    toggle();
  };

  return (
    <div>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Order</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">User</th>
                    <th scope="col">Items</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Address</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id} onClick={() => viewOrderDetails(order)}>
                      <td>{order.id}</td>
                      <td>
                        {order.user.name} <br />
                        {order.user.phone} <br />
                        {order.user.email}
                      </td>
                      <td>
                        {order.items.map((item, index) => (
                          <div key={index}>
                            {item.name} x{item.quantity}
                          </div>
                        ))}
                      </td>
                      <td>{order.paymentMethod}</td>
                      <td>{order.status}</td>
                      <td>{order.datetime}</td>
                      <td>{order.address}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>

            <Modal isOpen={modal} toggle={toggle} backdrop={true} size="lg">
              <ModalHeader>
                <h2>Order #{selectedOrder?.id}</h2>
              </ModalHeader>
              <ModalBody>
                {selectedOrder && (
                  <>
                    <Row className="mb-2">
                      <Col xs="6">
                        <h3 className="mb-0">Customer Details:</h3>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button
                          color="primary"
                          onClick={toggleCustomerCollapse}
                        >
                          {customerCollapse ? "Hide" : "Show"}
                        </Button>
                      </Col>
                    </Row>
                    <Collapse isOpen={customerCollapse}>
                      <Table striped >
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <td>{selectedOrder.user.name}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>{selectedOrder.user.phone}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{selectedOrder.user.email}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>{selectedOrder.address}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Collapse>

                    <Row className="mb-2">
                      <Col xs="6">
                        <h3>Order Details:</h3>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="primary" onClick={toggleOrderCollapse}>
                          {orderCollapse ? "Hide" : "Show"}
                        </Button>
                      </Col>
                    </Row>
                    <Collapse isOpen={orderCollapse}>
                      <Table >
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedOrder.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>${item.price.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Table striped >
                        <tbody>
                          <tr>
                            <th>Delivery Charges</th>
                            <td>${selectedOrder.deliveryCharges.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>${selectedOrder.total.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>Payment Method</th>
                            <td>{selectedOrder.paymentMethod}</td>
                          </tr>
                          <tr>
                            <th>Paid Amount</th>
                            <td>${selectedOrder.paidAmount.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Collapse>

                    <Row>
                      <Col xs="3">
                        <Button color="success" onClick={acceptOrder} block>
                          Accept
                        </Button>
                      </Col>
                      <Col xs="3">
                        <Button color="danger" onClick={rejectOrder} block>
                          Reject
                        </Button>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Input
                        className="form-control-alternative mt-3"
                        id="input-title"
                        placeholder="Reason for rejection"
                        type="text"
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                      />
                    </FormGroup>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Order;