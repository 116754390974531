import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col } from 'reactstrap';

function Rider(props) {
  const [name, setName] = useState(props.rider ? props.rider.name : '');
  const [username, setUsername] = useState(props.rider ? props.rider.username : '');
  const [password, setPassword] = useState(props.rider ? props.rider.password : '');
  const [phone, setPhone] = useState(props.rider ? props.rider.phone : '');
  const [available, setAvailable] = useState(props.rider ? props.rider.available : true);
  const [mainError, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [nameError, setNameError] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const onBlur = (setter, field, state) => {
    setter(!validateFunc({ [field]: state }, field));
  };

  function validateFunc(value, fieldName) {
    // Perform your validation logic here for each field
    // Return true if the value is valid, false otherwise

    if (fieldName === 'name') {
      // Example validation for the 'name' field
      return value && value.length <= 20; // Validate if the name is not empty and doesn't exceed 20 characters
    }

    if (fieldName === 'username') {
      // Example validation for the 'username' field
      return value && /^[a-zA-Z0-9]+$/.test(value); // Validate if the username contains only letters and numbers
    }

    // Add similar validations for other fields (password, phone, etc.)

    return true; // Default to true if no specific validation is defined
  }
  
  const onSubmitValidation = () => {
    const nameError = !validateFunc({ name: name }, 'name');
    const usernameError = !validateFunc({ username: username }, 'username');
    const passwordError = !validateFunc({ password: password }, 'password');
    const phoneError = !validateFunc({ phone: phone }, 'phone');

    setNameError(nameError);
    setUsernameError(usernameError);
    setPhoneError(phoneError);
    setPasswordError(passwordError);

    return nameError && usernameError && phoneError && passwordError;
  };

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.rider ? 'Edit Rider' : 'Add Rider'}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-name">
                      Name
                    </label>
                    <br />
                    <small>Character limit of max length 20</small>
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-name"
                        placeholder="e.g John Doe"
                        type="text"
                        maxLength="20"
                        value={name}
                        onChange={event => {
                          setName(event.target.value);
                        }}
                        onBlur={event => {
                          onBlur(setNameError, 'name', name);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-username">
                      Username
                    </label>
                    <br />
                    <small>Character limit of max length 20</small>
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="e.g ridername007"
                        maxLength="20"
                        type="text"
                        value={username}
                        onChange={event => {
                          setUsername(event.target.value.toLowerCase());
                        }}
                        onBlur={event => {
                          onBlur(setUsernameError, 'username', username);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <label className="form-control-label" htmlFor="input-phone">
                      Phone
                    </label>
                    <br />
                    <small>Character limit of max length 20</small>
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-phone"
                        placeholder="e.g 923458989989"
                        maxLength="20"
                        type="number"
                        value={phone}
                        onChange={event => {
                          setPhone(event.target.value);
                        }}
                        onBlur={event => {
                          onBlur(setPhoneError, 'phone', phone);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-password">
                      Password
                    </label>
                    <br />
                    <small>Character limit of max length 20</small>
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-password"
                        placeholder="e.g 132"
                        maxLength="20"
                        type="password"
                        value={password}
                        onChange={event => {
                          setPassword(event.target.value);
                        }}
                        onBlur={event => {
                          onBlur(setPasswordError, 'password', password);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-available">
                      Available
                    </label>
                    <FormGroup>
                      <label className="custom-toggle">
                        <input
                          defaultChecked={available}
                          type="checkbox"
                          onChange={event => {
                            setAvailable(event.target.checked);
                          }}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    {success && (
                      <div className="alert alert-success">
                        {success}
                      </div>
                    )}
                    {mainError && (
                      <div className="alert alert-danger">
                        {mainError}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={async e => {
                        e.preventDefault();
                        if (onSubmitValidation()) {
                          // Perform your save operation here
                          // You can access the form data as:
                          // name, username, password, phone, available
                        }
                      }}
                      size="md">
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Rider;
