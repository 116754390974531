import React from 'react';
import { Container, Row } from 'reactstrap';
import AuthNavbar from '../components/Navbars/AuthNavbar';
import AuthFooter from '../components/Footers/AuthFooter';

const AuthLayout = ({ children }) => (
  <div className="bg-default">
    <AuthNavbar />
    <Container>
      <Row className="justify-content-center mt-5">
        {children}
      </Row>
    </Container>
    <AuthFooter />
  </div>
);

export default AuthLayout;
