import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import config from "config";

function Category(props) {
  const {
    isEdit,
    file,
    setFile,
    iconFile,
    setIconFile,
    extras: initialExtras,
  } = props;
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [extras, setExtras] = useState([]); // Initialize with an empty array
  const [editExtras, setEditExtras] = useState([]);
  const [cat_no, setCatNo] = useState(isEdit && props.category ? props.category.cat_no : "");
  const [name, setName] = useState(isEdit && props.category ? props.category.name : "");
  const [slug, setSlug] = useState(isEdit && props.category ? props.category.slug : "");

  const [nameError, setNameError] = useState("");
  const [slugError, setSlugError] = useState("");
  const [catNoError, setCatNoError] = useState("");

  useEffect(() => {
    if (props.category) {
      setName(props.category.name);
      setCatNo(props.category.cat_no);
      setSlug(props.category.slug);
      setSelectedExtras(props.category.extras);
    }
  }, [props.category]);

  useEffect(() => {
    fetch(`${config.baseUrl}/extras/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (isEdit) {
          setEditExtras(data);
        } else {
          setExtras(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Extras:", error);
        // Handle the error, e.g., display an error message to the user
      });
  }, [isEdit]);

  const handleExtraChange = (event, extraId) => {
    if (event.target.checked) {
      setSelectedExtras((prevExtras) => [...prevExtras, extraId]);
    } else {
      setSelectedExtras((prevExtras) => prevExtras.filter((id) => id !== extraId));
    }
  };

  const isExtraSelected = (extraId) => {
    return selectedExtras.includes(extraId);
  };

  const handleSave = () => {
    // Ensure that the state is correct when saving
    console.log("selectedExtras:", selectedExtras);

    
    if (!cat_no || cat_no <= 0) {
      setCatNoError("Please enter valid category number * ");
      return;
    }
    if (!name || name.length < 2) {
      setNameError("Enter valid name * ");
      return;
    }
    if (!slug ) {
      setSlugError("Please enter slug * ");
      return;
    }

    setNameError("");
    setSlugError("");
    setCatNoError("");

    const newCategory = {
      name: name,
      cat_no: cat_no,
      slug: slug,
      image: file,
      extras: selectedExtras,
      icon: iconFile,
    };

    if (props.category) {
      newCategory.id = props.category.id;
      props.editCategory(newCategory);
    } else {
      props.addCategory(newCategory);
    }

    // Reset form fields and file inputs after saving
    setName("");
    setCatNo("");
    setSlug("");
    setSelectedExtras([]);
    setFile(null);
    setIconFile(null);
  };

  return (
    <Row>
      <Col className="order-xl-1">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {props.isEdit ? "Edit Category" : "Add Category"}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-image">
                      Upload Image
                    </label>
                    <FormGroup>
                      <label
                        htmlFor="file-upload"
                        className="btn btn-secondary"
                      >
                        <Input
                          type="file"
                          id="file-upload"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={(event) => {
                            setFile(event.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        Choose Image
                      </label>
                      {file && typeof file === "object" ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Category: ${name}`}
                          style={{ maxWidth: "50px", maxHeight: "50px" }}
                        />
                      ) : (
                        // Display the existing image for editing
                        props.isEdit &&
                        props.category &&
                        props.category.image && (
                          <img
                            src={config.baseUrl + props.category.image}
                            alt={`Category: ${props.category.name}`}
                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                          />
                        )
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-icon">
                      Upload Icon
                    </label>
                    <FormGroup>
                      <label
                        htmlFor="icon-upload"
                        className="btn btn-secondary"
                      >
                        <Input
                          type="file"
                          id="icon-upload"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={(event) => {
                            setIconFile(event.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        Choose Icon
                      </label>
                      {iconFile && typeof iconFile === "object" ? (
                        <img
                          src={URL.createObjectURL(iconFile)}
                          alt={`Category: ${name}`}
                          style={{ maxWidth: "50px", maxHeight: "50px" }}
                        />
                      ) : (
                        // Display the existing icon for editing
                        props.isEdit &&
                        props.category &&
                        props.category.icon && (
                          <img
                            src={config.baseUrl + props.category.icon}
                            alt={`Category: ${props.category.name}`}
                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                          />
                        )
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-item-no"
                      >
                        Category Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        min={0}
                        max={100} 
                        className="form-control-alternative"
                        id="input-item-no"
                        placeholder=""
                        type="number"
                        value={cat_no || ""}
                        onChange={(event) =>{ setCatNo(event.target.value);
                        setCatNoError("");
                        }}
                      />
                      { catNoError && <div className="text-danger">{catNoError}</div>}
                    </FormGroup>
                  </Col>
                </Row>  
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-title">
                      Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-title"
                        type="text"
                        maxLength="50"
                        value={name}
                        onChange={(event) => {setName(event.target.value);
                        setNameError("");
                        } }
                      />
                      { nameError && <div className="text-danger">{nameError}</div>}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-slug">
                      Slug <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        id="input-slug"
                        type="text"
                        value={slug}
                        onChange={(event) => {
                        setSlug(event.target.value);
                        setSlugError("");
                        }}
                      />
                      { slugError && <div className="text-danger">{slugError}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <label className="form-control-label" htmlFor="input-extras">
                      Option Groups
                    </label>
                    <FormGroup>
                      {isEdit ? (
                        // Editing existing category
                        editExtras && editExtras.map((extra) => (
                          <div key={extra.id} className="custom-control custom-checkbox">

                            <input
                              type="checkbox"
                              id={`extra-edit-${extra.id}`}
                              className="custom-control-input"
                              value={extra.id}
                              checked={isExtraSelected(extra.id)}
                              onChange={(event) => handleExtraChange(event, extra.id)}
                            />
                            <label className="custom-control-label" htmlFor={`extra-edit-${extra.id}`}>
                              {extra.description}
                            </label>
                          </div>
                        ))
                      ) : (
                        extras && extras.map((extra) => (
                          <div key={extra.id} className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id={`extra-add-${extra.id}`}
                              className="custom-control-input"
                              value={extra.id}
                              checked={isExtraSelected(extra.id)}
                              onChange={(event) => handleExtraChange(event, extra.id)}
                            />
                            <label className="custom-control-label" htmlFor={`extra-add-${extra.id}`}>
                              {extra.description}
                            </label>
                          </div>
                        ))
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                

                <Row>
                  <Col lg="6">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={handleSave}
                      size="md"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Category;
