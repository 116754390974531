import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import AddonComponent from "../Addon/Addon";
import config from "config";

function Food(props) {
  const [addonsModal, setAddonsModal] = useState(false);
  const { isEdit, file, setFile } = props;
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const [name, setName] = useState(isEdit && props.food ? props.food.name : "");
  const [slug, setSlug] = useState(isEdit && props.food ? props.food.slug : "");

  const [nameError, setNameError] = useState("");
  const [itemNoError, setItemNoError] = useState("");
  const [slugError, setSlugError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [description, setDescription] = useState(
    isEdit && props.food ? props.food.description : ""
  );
  const [itemNo, setItemNo] = useState(
    isEdit && props.food ? props.food.itemno : ""
  );
  const [price, setPrice] = useState(
    isEdit && props.food ? props.food.price : ""
  );
  const [ptype, setPtype] = useState(
    isEdit && props.food ? props.food.ptype : ""
  );

  const [isFeature, setIsFeature] = useState(
    isEdit && props.food ? props.food.feature : false
  );
  const [isAvailable, setIsAvailable] = useState(
    isEdit && props.food ? props.food.available : true
  );


  useEffect(() => {
    if (props.food) {
      setSlug(props.food.slug);
      setDescription(props.food.description);
      setItemNo(props.food.itemno);
      setPrice(props.food.price);
      setPtype(props.food.ptype);
      setCategory(props.food.category);
      setIsFeature(props.food.feature);
      setIsAvailable(props.food.available);
    }
  }, [props.food]);

  // Fetch categories from the API
  useEffect(() => {
    fetch(`${config.baseUrl}/categories/`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleSave = () => {

    if (!itemNo || itemNo <= 0) {
      setItemNoError("Please enter valid itemNo * ");
      return;
    }
    if (!name || name.length < 2) {
      setNameError("Enter valid name * ");
      return;
    }
    if (!slug ) {
      setSlugError("Please enter slug * ");
      return;
    }
    if (!category) {
      setCategoryError("Please select category * ");
      return;
    }
    setNameError("");
    setItemNoError("");
    setSlugError("");
    setCategoryError("");

    const newFood = {
      itemno: itemNo,
      name: name,
      slug: slug,
      description: description,
      price: price,
      ptype: ptype,
      feature: isFeature,
      available: isAvailable,
      category: category,
      image: file,

      

    };

    console.log(newFood);

    if (props.food) {
      newFood.id = props.food.id;
      props.editFood(newFood);
    } else {
      props.addFood(newFood);
    }

    // Reset form fields
    setName("");
    setDescription("");
    setSlug("");
    setItemNo("");
    setPrice("");
    setPtype("");
    setCategory("");
    setFile(null);
    setIsFeature(false);
    setIsAvailable(false);
  };


  const handleFeatureToggle = () => {
    setIsFeature(!isFeature);
  };
const handleAvailableToggle = () => {
    setIsAvailable(!isAvailable);
  };
  const toggleModal = () => {
    setAddonsModal(!addonsModal);
  };



  return (
    <>
      <Row>
        <Col className="order-xl-1">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {props.isEdit ? "Edit Menu" : "Add Menu"}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label
                        className="form-control-label"
                        htmlFor="input-slug"
                      >
                        Upload Image
                      </label>
                      <FormGroup>
                        <label
                          htmlFor="file-upload"
                          className="btn btn-secondary"
                        >
                          <Input
                            type="file"
                            id="file-upload"
                            accept=".jpg, .jpeg, .png, .gif"
                            onChange={(event) => {
                              setFile(event.target.files[0]);
                            }}
                            style={{ display: "none" }}
                          />
                          Choose Image
                        </label>
                        {file && typeof file === "object" ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Food: ${name}`}
                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                          />
                        ) : (
                          props.isEdit &&
                          props.food &&
                          props.food.image && (
                            <img
                              src={config.baseUrl + props.food.image}
                              alt={`Food: ${props.food.name}`}
                              style={{ maxWidth: "50px", maxHeight: "50px" }}
                            />
                          )
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label">Available</label>
                        <br />
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={isAvailable}
                            onChange={handleAvailableToggle}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-item-no"
                        >
                          Item Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          min={0}
                          max={100} 
                          className="form-control-alternative"
                          id="input-item-no"
                          placeholder="0"
                          type="number"
                          value={itemNo || ""}
                          onChange={(event) => {
                          setItemNo(event.target.value);
                          setItemNoError("");
                        }}
                      />
                      {itemNoError && <div className="text-danger">{itemNoError}</div>}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-title"
                        >
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-title"
                          type="text"
                          value={name || ""}
                          onChange={(event) => {
                          setName(event.target.value);
                          setNameError("");
                        }}
                      />
                      {nameError && <div className="text-danger">{nameError}</div>}
                    
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-slug"
                        >
                          Slug <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-slug"
                          type="text"
                          value={slug || ""}
                          onChange={(event) => {
                          setSlug(event.target.value);
                          setSlugError("");
                        }}

                        />
                        {slugError && <div className="text-danger">{slugError}</div>}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-category"
                        >
                          Category <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          name="select"
                          id="input-category"
                          value={category || ""}
                          onChange={(event) => {
                          setCategory(event.target.value);
                          setCategoryError("");
                        }}
                        >
                          <option>Select</option>
                          {categories.map((cat) => (
                            <option key={cat.id} value={cat.id || ''}>
                              {cat.name}
                            </option>
                          ))}
                        </Input>
                        {categoryError && (
                          <div className="text-danger">{categoryError}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-price"
                        >
                          Price 
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-price"
                          type="number"
                          max={100}
                          min={0}
                          value={price || ""}
                          onChange={(event) => setPrice(event.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-ptype"
                        >
                          Type 
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-ptype"
                          type="text"
                          value={ptype || ""}
                          onChange={(event) => setPtype(event.target.value)
                          }
                        />
                        
                      </FormGroup>
                    </Col>
                   
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-description"
                        >
                          Description
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-description"
                          type="textarea"
                          value={description || ""}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label">Popular</label>
                        <br />
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            checked={isFeature}
                            onChange={handleFeatureToggle}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </FormGroup>
                    </Col>
                    
                  </Row>


                </div>

                <Row className="mt-2 justify-content-center">
                  <Col>
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addonsModal}
        toggle={toggleModal}
      >
        <AddonComponent />
      </Modal>
    </>
  );
}

export default Food;