import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Badge,
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  CardHeader,
  Table,
} from "reactstrap";
import config from "config";
import OptionList from "./OptionItemTable.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
function OptionItems() {
  const [editModal, setEditModal] = useState(false);
  const [optionItems, setOptionItems] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
const [optionItemToDelete, setOptionItemToDelete] = useState(null);


  const openDeleteConfirmation = (optionItem) => {
  setOptionItemToDelete(optionItem);
  setDeleteConfirmation(true);
};

  const toggleModal = (options) => {
    setEditModal(!editModal);
    setOptionItems(options);
  };

  const fetchOptionItems = () => {
    fetch(`${config.baseUrl}/extraitems/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setOptionItems(data);
        } else {
          setOptionItems([]);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchOptionItems();
  }, []);

  const addOptionItem = async (newOptionItem) => {
    try {
      const formData = new FormData();
      formData.append("name", newOptionItem.name);
      formData.append("description", newOptionItem.description);
      formData.append("extra", newOptionItem.description);

      if (newOptionItem.price) {
        formData.append("price", newOptionItem.price);
      }

      const response = await fetch(`${config.baseUrl}/extraitems/`, {
        method: "POST",
        body: formData,
      });

      console.log(formData, newOptionItem);

      if (!response.ok) {
        console.error("Error Response:", response.status, response.statusText);
        const errorData = await response.text();
        console.error("Error Data:", errorData);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setOptionItems((prevOptionItems) => [...prevOptionItems, data]);
      setEditModal(false);
    } catch (error) {
      console.error("Error adding Option Item:", error);
    }
  };

  const deleteOptionItem = async (optionItemId) => {
    try {
      await fetch(`${config.baseUrl}/extraitems/${optionItemId}/`, {
        method: "DELETE",
      });
      setOptionItems((prevOptionItems) => prevOptionItems.filter((option) => option.id !== optionItemId));
    } catch (error) {
      console.error("Error deleting Option Item:", error);
    }
  };

  const editOptionItem = (editedOptionItem) => {
    const formData = new FormData();
    formData.append('name', editedOptionItem.name);
    formData.append("description", editedOptionItem.description);
    formData.append('extra', editedOptionItem.extra);

    if (editedOptionItem.price) {
      formData.append("price", editedOptionItem.price);
    }

    fetch(`${config.baseUrl}/extraitems/${editedOptionItem.id}/`, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setOptionItems((prevOptionItems) => {
          if (Array.isArray(prevOptionItems)) {
            return prevOptionItems.map((option) =>
              option.id === data.id ? data : option
            );
          }
          return prevOptionItems;
        });
        setEditModal(false);
        fetchOptionItems();
      })
      .catch((error) => {
        console.error('Error editing option:', error);
      });
  };

  const actionButtons = (option) => {
    return (
      <>
        <FontAwesomeIcon
          icon={faEdit}
          href="#Edit"
          style={{ color: "#2d74d6" , cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            toggleModal(option);
          }}
          color="primary"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faTrash}
          href="#Delete"
          style={{ color: "red" , cursor: "pointer"}}
          onClick={(e) => {
            e.preventDefault();
            openDeleteConfirmation(option);
          }}
        />
          



      </>
    );
  };

  const confirmDeleteOptionItem = async () => {
  if (optionItemToDelete) {
    await deleteOptionItem(optionItemToDelete.id);
    setDeleteConfirmation(false);
  }
};

const cancelDelete = () => {
  setOptionItemToDelete(null);
  setDeleteConfirmation(false);
};


  const setSelectedOption = (selectedOption) => {
    setOptionItems(selectedOption);
  };

  return (
    <>
        <OptionList
        addOptionItem={addOptionItem}
        fetchOptionItems={fetchOptionItems}
        isEdit={false}
        />


        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Option Items</h3>
              </CardHeader>
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    
                    <th>Title</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Option Groups</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {optionItems && optionItems.length > 0 ? (
                    optionItems.map((option) => (
                      <tr key={option.id}>
                        <td>{option.name}</td>
                        <td>{option.description}</td>
                        <td>{option.price}</td>
                        <td>{option.extra_description}</td>
                        <td>{actionButtons(option, setSelectedOption)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal();
          }}
        >
          <OptionList
            option={optionItems}
            editOptionItem={editOptionItem}
            isEdit={true}
          />
        </Modal>
          {deleteConfirmation && (
  <Modal isOpen={deleteConfirmation} toggle={cancelDelete} centered>
    <ModalHeader toggle={cancelDelete}>Confirm Deletion</ModalHeader>
    <ModalBody>
      Are you sure you want to delete this option item?
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={cancelDelete}>
        Cancel
      </Button>
      <Button color="danger" onClick={confirmDeleteOptionItem}>
        Delete
      </Button>
    </ModalFooter>
  </Modal>
)}

    </>
  );
}

export default OptionItems;
