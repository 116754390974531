import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Card, Badge,  CardHeader } from 'reactstrap';
import Category from 'components/Category.js';
import config from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function Categories() {
  const [editModal, setEditModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const toggleModal = (category) => {
    setEditCategory(category);
    setEditModal(!editModal);
  };


  const fetchCategories = () => {
    fetch(`${config.baseUrl}/categories/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          setCategories([]);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const addCategory = async (newCategory) => {
    try {
      const formData = new FormData();
      formData.append('cat_no', newCategory.cat_no);
      formData.append('name', newCategory.name);
      formData.append('slug', newCategory.slug);

      if (newCategory.icon) {
        formData.append('Icon', newCategory.icon);
      }

      newCategory.extras.forEach((extraId) => {
        formData.append('extras', extraId);
      });

      if (newCategory.image) {
        formData.append('image', newCategory.image);
      }

      const response = await fetch(`${config.baseUrl}/categories/`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Category added:", data); // Add this console.log
      setCategories((prevCategories) => [...prevCategories, data]);
      setEditModal(false);
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      await fetch(`${config.baseUrl}/categories/${categoryId}/`, {
        method: 'DELETE',
      });
      setCategories((prevCategories) => prevCategories.filter((category) => category.id !== categoryId));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const openDeleteConfirmation = (category) => {
    setCategoryToDelete(category);
    setDeleteCategoryModal(true);
  };

  const editCategoryFunction = (editedCategory) => {
    const formData = new FormData();
    formData.append('cat_no', editedCategory.cat_no);
    formData.append('name', editedCategory.name);
    formData.append('slug', editedCategory.slug);

    if (editedCategory.icon) {
      formData.append('icon', editedCategory.icon);
    }

    editedCategory.extras.forEach((extraId) => {
      formData.append('extras', extraId);
    });

    if (editedCategory.image) {
      formData.append('image', editedCategory.image);
    }

    fetch(`${config.baseUrl}/categories/${editedCategory.id}/`, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Category edited:", data);

        // Update the state of categories with the edited category
        setCategories((prevCategories) =>
          prevCategories.map((cat) => (cat.id === data.id ? data : cat))
        );

        setEditModal(false);
      })
      .catch((error) => {
        console.error("Error editing category:", error);
      });
  };


  const actionButtons = (row) => {
    return (
      <>
        <FontAwesomeIcon
          style={{ color: "#2d74d6", cursor: "pointer" }}
          icon={faEdit}
          onClick={(e) => {
            e.preventDefault();
            toggleModal(row);
          }}
          color="primary"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faTrash}
          href="#pablo"
          style={{ color: "red", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            openDeleteConfirmation(row);
          }}
        />
          
      </>
    );
  };

  const handleDeleteConfirmation = async () => {
    if (categoryToDelete) {
      await deleteCategory(categoryToDelete.id);
      setCategoryToDelete(null);
      setDeleteCategoryModal(false);
    }
  };


  return (
    <>
      <div className="header bg-gradient-dark pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Category
          addCategory={addCategory}
          fetchCategories={fetchCategories}
          isEdit={false}
          file={file}
          setFile={setFile}
          iconFile={iconFile}
          setIconFile={setIconFile}
          selectedExtras={selectedExtras}
          setSelectedExtras={setSelectedExtras}
        />

        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">Category List</h3>
              </CardHeader>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Cat No.</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Option Groups</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories && categories.length > 0 ? (
                    categories.map((cat) => (
                      <tr key={cat.id}>
                        <td>{cat.cat_no}</td>
                        <td>
                          {cat.image && (
                            <img
                              src={config.baseUrl + cat.image}
                              alt={cat.name}
                              style={{ maxWidth: '50px', maxHeight: '50px' }}
                            />
                          )}
                        </td>
                        <td>{cat.name}</td>
                        <td>
                          {cat.extra_description.map((extra) => (
                            <span key={extra}>{extra}<br/> </span>
                          ))}
                        </td>
                        
                        <td>{actionButtons(cat)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={editModal}
          toggle={() => {
            toggleModal(null);
          }}
        >
          {editCategory && (
            <Category
              category={editCategory}
              editCategory={editCategoryFunction}
              isEdit={true}
              file={file}
              setFile={setFile}
              iconFile={iconFile}
              setIconFile={setIconFile}
              selectedExtras={selectedExtras}
            />
          )}
        </Modal>
        <Modal
          isOpen={deleteCategoryModal}
          toggle={() => setDeleteCategoryModal(false)}
          centered
        >
          <ModalHeader toggle={() => setDeleteCategoryModal(false)}>
            Confirm Deletion
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this category?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setDeleteCategoryModal(false)}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDeleteConfirmation}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>

      </Container>
    </>
  );
}

export default Categories;
