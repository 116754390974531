import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  Alert,
} from "reactstrap";

function Addon(props) {
  const initialAddonState = {
    title: "",
    description: "",
    quantity_minimum: 0,
    quantity_maximum: 1,
    options: [],
    titleError: false,
    descriptionError: false,
    optionsError: false,
    quantity_minimumError: false,
    quantity_maximumError: false,
  };

  const [addons, setAddons] = useState([initialAddonState]);
  const [optionsModal, setOptionsModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [addonIndex, setAddonIndex] = useState(0);
  const [availableOptions, setAvailableOptions] = useState([
    { id: 1, name: "Option 1" },
    { id: 2, name: "Option 2" },
    { id: 3, name: "Option 3" },
    // Add more options as needed
  ]);

  function validateAddon(addon) {
    const errors = {};

    if (!addon.title.trim()) {
      errors.title = "Title is required";
    }

    if (!addon.description.trim()) {
      errors.description = "Description is required";
    }

    if (
      isNaN(addon.quantity_minimum) ||
      addon.quantity_minimum < 0 ||
      addon.quantity_minimum > addon.quantity_maximum
    ) {
      errors.quantity_minimum = "Invalid minimum quantity";
    }

    if (
      isNaN(addon.quantity_maximum) ||
      addon.quantity_maximum < 1 ||
      addon.quantity_maximum < addon.quantity_minimum
    ) {
      errors.quantity_maximum = "Invalid maximum quantity";
    }

    if (addon.options.length === 0) {
      errors.options = "Select at least one option";
    }

    return errors;
  }

  const handleChange = (event, index, field) => {
    const newAddons = [...addons];
    newAddons[index][field] = event.target.value;
    setAddons(newAddons);
  };

  const toggleModal = (index) => {
    setOptionsModal(!optionsModal);
    setAddonIndex(index);
  };

  const handleOptionSelection = (index, id) => {
    const newAddons = [...addons];
    const optionIndex = newAddons[index].options.indexOf(id);

    if (optionIndex === -1) {
      newAddons[index].options.push(id);
    } else {
      newAddons[index].options.splice(optionIndex, 1);
    }
    setAddons(newAddons);
  };

  const handleAddAddon = () => {
    const newAddons = [...addons];
    newAddons.push(initialAddonState);
    setAddons(newAddons);
  };

  const handleRemoveAddon = (index) => {
    if (addons.length === 1) {
      return;
    }
    const newAddons = [...addons];
    newAddons.splice(index, 1);
    setAddons(newAddons);
  };

  const handleSave = () => {
    const validationErrors = addons.map(validateAddon);
    const hasErrors = validationErrors.some(
      (errors) => Object.keys(errors).length > 0
    );

    if (hasErrors) {
      // Handle validation errors
      setErrorMessage("One or more addons have validation errors.");
      setSuccessMessage("");
    } else {
      // Submit the data or perform other actions here
      setSuccessMessage("Addons saved successfully.");
      setErrorMessage("");
    }
  };

  const handleDismiss = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <Card>
      <CardHeader>Addons</CardHeader>
      <CardBody className='bg-secondary shadow'>
        <Form>
          {addons.map((addon, index) => (
            <div key={index}>
              {/* Addon fields */}
              <Row>
                <Col lg="6">
                  {/* Title */}
                  <FormGroup>
                    <Label for={`addon-title-${index}`}>Title</Label>
                    <Input
                      type="text"
                      id={`addon-title-${index}`}
                      placeholder="e.g. Pepsi"
                      value={addon.title}
                      onChange={(event) => handleChange(event, index, "title")}
                    />
                  </FormGroup>
                  {/* Description */}
                  <FormGroup>
                    <Label for={`addon-description-${index}`}>
                      Description
                    </Label>
                    <Input
                      type="text"
                      id={`addon-description-${index}`}
                      placeholder="e.g. Optional"
                      value={addon.description}
                      onChange={(event) =>
                        handleChange(event, index, "description")
                      }
                    />
                  </FormGroup>
                  {/* Quantity Minimum */}
                  <FormGroup>
                    <Label for={`addon-quantity-minimum-${index}`}>
                      Quantity Minimum
                    </Label>
                    <Input
                      type="number"
                      id={`addon-quantity-minimum-${index}`}
                      placeholder="e.g. 90.25"
                      value={addon.quantity_minimum}
                      onChange={(event) =>
                        handleChange(event, index, "quantity_minimum")
                      }
                    />
                  </FormGroup>
                  {/* Quantity Maximum */}
                  <FormGroup>
                    <Label for={`addon-quantity-maximum-${index}`}>
                      Quantity Maximum
                    </Label>
                    <Input
                      type="number"
                      id={`addon-quantity-maximum-${index}`}
                      placeholder="e.g. 90.25"
                      value={addon.quantity_maximum}
                      onChange={(event) =>
                        handleChange(event, index, "quantity_maximum")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {/* Options */}
                  <FormGroup>
                    <Label className="d-block m-0">Options</Label>
                    {!addon.options.length && (
                      <small className="text-danger">
                        Select at least one Option
                      </small>
                    )}
                    {availableOptions.map((option) => (
                      <FormGroup key={option.id} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={`addon-option-${index}-${option.id}`}
                            checked={addon.options.includes(option.id)}
                            onChange={() =>
                              handleOptionSelection(index, option.id)
                            }
                          />{" "}
                          {option.name}
                        </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          ))}
          {/* Add/Remove Addons buttons */}
          <Row>
            <Col>
              <Button color="primary" onClick={handleSave}>
                Save
              </Button>
            </Col>
          </Row>
          <Alert
            color="success"
            isOpen={!!successMessage}
            toggle={handleDismiss}
          >
            {successMessage}
          </Alert>
          <Alert color="danger" isOpen={!!errorMessage} toggle={handleDismiss}>
            {errorMessage}
          </Alert>
        </Form>
      </CardBody>
      {/* Options modal */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={optionsModal}
        toggle={() => toggleModal()}
      >
        {/* Render options modal content here */}
      </Modal>
    </Card>
  );
}

export default Addon;
