import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Delivery(props) {
  const [deliveryCharges, setDeliveryCharges] = useState(props.deliveryCharges || 0);
  const [deliveryChargesError, setDeliveryChargesError] = useState(null);

  const onBlur = (setter, field, event) => {
    setter(!event.target.value.trim()); // Check if the field is empty
  };

  const validateInput = () => {
    const deliveryChargesResult = !isNaN(deliveryCharges);
    setDeliveryChargesError(!deliveryChargesResult);
    return deliveryChargesResult;
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Delivery Charges</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-deliverycharges">
                    Price
                  </label>
                  <FormGroup
                    className={
                      deliveryChargesError === null
                        ? ''
                        : deliveryChargesError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-deliverycharges"
                      placeholder="e.g 30.00"
                      type="number"
                      defaultValue={deliveryCharges}
                      onChange={(event) => {
                        setDeliveryCharges(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(setDeliveryChargesError, 'deliveryCharges', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    disabled
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Delivery;
