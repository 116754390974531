import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, FormGroup, Form, Input, Button } from 'reactstrap';

function Order(props) {
  const [prefix, prefixSetter] = useState(props.prefix || '');
  const [prefixError, prefixErrorSetter] = useState(null);

  const validateInput = () => {
    const result = !!prefix.trim(); // Check if prefix is not empty
    prefixErrorSetter(!result);
    return result;
  };

  const onBlur = (setter, field, event) => {
    setter(!event.target.value.trim()); // Check if the field is empty
  };

  return (
    <Row className="mt-3">
      <div className="col">
        <Card className="shadow bg-secondary shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Order</h3>
          </CardHeader>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="8">
                  <label className="form-control-label" htmlFor="input-orderid">
                    OrderID prefix
                  </label>
                  <FormGroup
                    className={
                      prefixError === null
                        ? ''
                        : prefixError
                          ? 'has-success'
                          : 'has-danger'
                    }>
                    <Input
                      className="form-control-alternative"
                      id="input-orderid"
                      placeholder="e.g FOOD-"
                      type="text"
                      defaultValue={prefix}
                      onChange={(event) => {
                        prefixSetter(event.target.value);
                      }}
                      onBlur={(event) => {
                        onBlur(prefixErrorSetter, 'prefix', event);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button
                    className="mb-2"
                    type="button"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validateInput()) {
                        // Handle saving configuration here
                      }
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}

export default Order;
